import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import WithService from '../../services/hoc'
import { useDispatch, useSelector } from 'react-redux'
import { newsRequested, moreNewsRequested, dataError } from '../../redux/actions'

import Spinner from '../../components/Spinner/Spinner'

import './news.sass'
// import { useInView } from 'react-intersection-observer'


const News = (props) => {
  const { t } = useTranslation()
  const [offsetNews, setOffsetNews] = useState(10)
  const [newsLength, setNewsLength] = useState(10)
  const [showBtn, setShowBtn] = useState(true)

  const news = useSelector((state) => state.news)
  const language = useSelector((state) => state.language)
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = `${t("nav.news")} - CARGURU`

    const { Service } = props
    Service.getNews({ limit: newsLength, lng: language })
      .then(res => {
        setNewsLength(res?.result?.length)
        dispatch(newsRequested({ lng: language, data: res?.result }))
      })
      .catch((error) => {
        console.error(error)
        dispatch(dataError())
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])


  const funcStartTimeout = useRef(null)
  const getNextNews = () => {
    if (funcStartTimeout.current) return

    funcStartTimeout.current = setTimeout(() => {
      funcStartTimeout.current = null
    }, 1000)

    const { Service } = props
    Service.getNews({ offset: offsetNews, lng: language })
      .then(res => {
        setNewsLength(newsLength + res?.result?.length)
        setOffsetNews(offsetNews + 10)
        dispatch(moreNewsRequested({ lng: language, data: res?.result }))
      })
      .catch((error) => {
        console.error(error)
        setShowBtn(false)
      })
  }

  // const { ref, inView } = useInView({
  //   threshold: 0,
  // })

  // const [scrollPosition, setScrollPosition] = useState(0)
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.pageYOffset > scrollPosition) {
  //       setScrollPosition(window.pageYOffset)
  //       if (inView) getNextNews()
  //     } else if (window.pageYOffset <= scrollPosition) {
  //       setScrollPosition(window.pageYOffset)
  //     }
  //   }

  //   window.addEventListener('scroll', handleScroll, { passive: true })
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [scrollPosition])


  if (!news) return <Spinner onSmall />

  return (
    <div className="news">
      {news.map((item, index) =>
        <Link to={`/article/${item?.id}`} key={`${item?.id}_${index}`} className="news-block">
          <div className="news-block__date">
            <div className="news-date">
              <span className="news-date__day">{(item?.date_day < 10) ? `0${item?.date_day}` : item?.date_day}</span>
              <span className="news-date__month">
                <span>{item?.date_month}</span>
                <span>{item?.date_year}</span>
              </span>
            </div>

            <div className="news-arrow"></div>
          </div>

          <h2 className="news-title">
            {item?.title}
            <span>{t("nav.more")}</span>
          </h2>
        </Link>
      )}

      {/* <div ref={ref}>test</div> */}
      {/* <Spinner onSmall /> */}

      {(!(offsetNews > newsLength) && !(newsLength % 10)) && showBtn && (
        <button className="news-btn" onClick={getNextNews}>{t("button.news")}</button>
      )}
    </div>
  )
}

export default WithService()(News)