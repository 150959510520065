import React, { useRef, useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Route, Link, useLocation } from 'react-router-dom'
import ModalVideo from 'react-modal-video'

import { useDispatch, useSelector } from 'react-redux'
import { ratesSwitcher, hidePromoRate } from '../../redux/actions'

import Bonus from '../Bonus/Bonus'

import './page.sass'
import { isSecretDealRate, showFreeInfo } from '../../App/App'


const Page = (props) => {
  const location = useLocation().pathname
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const diff = useSelector((state) => state.diff)
  const ratesSwitch = useSelector((state) => state.rates)
  const hasCookies = useSelector((state) => state.cookies)

  const [openVideo, setOpenVideo] = useState(false)

  const showPromo = useSelector((state) => state.showPromoRate)
  const [stylePromo, setStylePromo] = useState(false)

  const leftTitle = useRef()
  const rightTitle = useRef()
  const checkbox = useRef()

  const checkedTrue = (titleClick = false) => {
    leftTitle.current.classList.remove('active')
    rightTitle.current.classList.add('active')

    dispatch(ratesSwitcher(1))

    if (titleClick) {
      checkbox.current.checked = true
    }
  }
  const checkedTrueClicked = () => checkedTrue(true)

  const checkedFalse = (titleClick = false) => {
    leftTitle.current.classList.add('active')
    rightTitle.current.classList.remove('active')

    dispatch(ratesSwitcher(0))

    if (titleClick) {
      checkbox.current.checked = false
    }
  }
  const checkedFalseClicked = () => checkedFalse(true)

  const pageClassName = (location === '/rates' || location === '/news') ? 'page-wrapper page-wrapper__reverse' : 'page-wrapper'

  useEffect(() => {
    if (hasCookies && !localStorage.getItem('hidePromoRate'))
      dispatch(hidePromoRate(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const promoClose = () => {
    if (hasCookies) localStorage.setItem('hidePromoRate', true)
    setStylePromo(true)

    setTimeout(() => dispatch(hidePromoRate(false)), 1000)
  }


  return (<>
    <section className="page">
      <div className="container">
        <div className={pageClassName}>
          <div className="page-content">
            {props.children}
          </div>
          <div className="page-aside">
            {location !== '/rates' ? (
              <>
                {location === '/loyalty/bonuses-and-levels' ? (
                  <div className="bonuses-aside">
                    <svg className="bonuses-aside__img" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16" cy="16" r="16" fill="#E01054" />
                      <path d="M16 25C17.1046 25 18 24.1046 18 23C18 21.8954 17.1046 21 16 21C14.8954 21 14 21.8954 14 23C14 24.1046 14.8954 25 16 25Z" fill="white" />
                      <path d="M14 7H18V19H14V7Z" fill="white" />
                    </svg>
                    <div className="bonuses-aside__text">
                      <Trans>{t("loyalty.bonuses-and-levels.details.info")}</Trans>
                    </div>
                  </div>
                ) : (
                  <>
                    {location === '/loyalty/referral-program' ? (<>
                      <ModalVideo
                        channel='youtube'
                        videoId="mpbZcngsgr0"
                        autoplay
                        youtube={{ mute: 1, autoplay: 1 }}
                        isOpen={openVideo}
                        onClose={() => setOpenVideo(false)}
                      />

                      <div className="page-contact referral-instruction__aside">
                        <div className="referral-instruction__aside-video" onClick={() => setOpenVideo(true)}>
                          <img src='/img/loyalty/referral/video.jpg' alt="video" />

                          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 0C7.61175 0 0 7.61175 0 17C0 26.3883 7.61175 34 17 34C26.3883 34 34 26.3883 34 17C34 7.61175 26.3883 0 17 0ZM12.75 24.0833V9.91667L25.5 17.2068L12.75 24.0833Z" fill="#E01254" />
                          </svg>
                        </div>
                        <div className="referral-instruction__aside-wrapper">
                          <div className="referral-instruction__aside-text">
                            {t("loyalty.referral-program.video")}
                          </div>

                          <div className="contact">
                            <span className="page-contact__text">{t("aside.title")}</span>
                            <a href={`mailto:${t("contacts.mail_support")}`} className="page-contact__mail">{t("contacts.mail_support")}</a>
                            <a href={`tel:${t("contacts.phone")}`} className="page-contact__phone">{t("contacts.phone")}</a>
                          </div>
                        </div>
                      </div>
                    </>) : (
                      <div className="page-contact">
                        <span className="page-contact__text">{t("aside.title")}</span>
                        <a href={`mailto:${t("contacts.mail_support")}`} className="page-contact__mail">{t("contacts.mail_support")}</a>
                        <a href={`tel:${t("contacts.phone")}`} className="page-contact__phone">{t("contacts.phone")}</a>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (<>
              {showFreeInfo && !isSecretDealRate && showPromo && (
                <div
                  className={`rates-promo mob ${stylePromo ? 'disable' : ''}`}
                  style={{ backgroundImage: `url(${process.env.PUBLIC_URL + './img/rates/bg/promo.svg'})` }}
                >
                  <div className="rates-promo__text">
                    <Trans>{t("rate.header.promo")}</Trans>
                  </div>
                  <div
                    onClick={promoClose}
                    className="rates-promo__close">
                    <svg id="times" viewBox="0 0 352 512"><path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
                  </div>
                </div>
              )}
              <div className="rates-switch">
                <div className="rates-switch__header">
                  {t("rate.header.risk_title")}
                </div>

                <div className="rates-switch__switcher">
                  <span
                    className="map-checkbox__title active"
                    ref={leftTitle}
                    onClick={checkedFalseClicked}
                  >Basic</span>
                  <label className="map-block__switch">
                    <input
                      className="map-block__input"
                      type="checkbox"
                      ref={checkbox}
                      onClick={(e) => { e.target.checked ? checkedTrue() : checkedFalse() }}
                    />
                    <span className="map-block__slider"></span>
                  </label>
                  <span
                    className="map-checkbox__title"
                    ref={rightTitle}
                    onClick={checkedTrueClicked}
                  >Pro</span>
                </div>

                <div className="rates-switch__price">
                  <div className="rates-switch__price_title">
                    {t("rate.aside.risk_header")}
                  </div>
                  <div className="rates-switch__price_number">
                    <span>
                      {diff[ratesSwitch].deductible}
                    </span>
                  </div>
                  <div className="rates-switch__price_subtitle">
                    {t("rate.aside.risk_desc")}
                  </div>
                </div>

                <div className="rates-switch__info">
                  <div className="rates-switch__info_item">
                    {t("rate.aside.info_title_1")}
                    <span>
                      {diff[ratesSwitch].timeToBookCar} {t("main.min")}
                    </span>
                  </div>
                  <div className="rates-switch__info_item">
                    {t("rate.aside.info_title_2")}
                    <span>
                      {diff[ratesSwitch].coinsForRefueling} ₲
                    </span>
                  </div>
                  <div className="rates-switch__info_item">
                    {t("rate.aside.info_title_3")}
                    <span>
                      {diff[ratesSwitch].coinsForCharging} ₲
                    </span>
                  </div>
                  <div className="rates-switch__info_item">
                    {t("rate.aside.info_title_4")}
                    <span>
                      {diff[ratesSwitch].forConnectingToCharger} ₲
                    </span>
                  </div>
                </div>

                {showFreeInfo && !isSecretDealRate && (
                  <div className="rates-switch__promo">
                    <Trans>{t("rate.header.promo")}</Trans>
                  </div>
                )}

                <div className="rates-switch__coin">
                  <div className="rates-switch__coin_title">
                    {t("rate.aside.coin_title")}
                  </div>

                  <div className="rates-switch__coin_subtitle">
                    {t("rate.aside.coin_subtitle")}
                  </div>
                </div>

              </div>
            </>)}
          </div>
        </div>
      </div>
    </section>

    <Route path={`/guide`}>
      {location.slice(1).split('/')[1] && location.slice(1).split('/')[1] !== 'notifications' && (
        <section className="guide-next">
          <div className="container">
            <div className="guide-next__block">

              <span className="guide-next__title">{t("guide.further")}</span>

              <Link to={'/guide/' + t(`guide.${location.slice(1).split('/')[1]}.next_link`)} className="guide-block next">
                <h2 className="guide-title"><span>{t(`guide.${location.slice(1).split('/')[1]}.next_number`)}</span> {t(`guide.${location.slice(1).split('/')[1]}.next_title`)} </h2>
                <div className="guide-arrow"></div>
              </Link>

              <span className="guide-next__subtitle">{t("guide.subtitle")}</span>
            </div>
          </div>
        </section>
      )}
    </Route>

    {(location !== '/loyalty/bonuses-and-levels' && location !== '/loyalty/referral-program') && <Bonus />}
  </>)
}

export default Page