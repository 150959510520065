import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { isSecretDealRate, showFreeInfo } from '../../../App/App'

const RatesItem = ({ car, ratesSwitch }) => {
	const { t } = useTranslation()
	const { hash } = useLocation()

	const { title, rates } = car

	useEffect(() => {
		if (hash === `#${title.replace(/[^a-z0-9]/gi, '')}`) {
			const car = document.getElementById(hash.replace('#', ''))
			if (car) car.classList.add('active')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hash])

	const [widthRatePeriod, setWidthRatePeriod] = useState(12)
	useEffect(() => {
		setWidthRatePeriod(Math.floor(100 / (Object.keys(rates[ratesSwitch].currentPeriods).length - 1)))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='rates-item' id={title.replace(/[^a-z0-9]/gi, '')}>
			<div
				className='rates-item__header'
				onClick={e => e.currentTarget.parentNode.classList.toggle('active')}>
				<div className='rates-item__title'>
					<h2>
						{title.split(' ').slice(1).join(' ') === 'e-up!' ? (
							'VW e-up!'
						) : title.split(' ').slice(1).join(' ') === 'Passat variant' ? (
							<>
								Passat <span>Variant</span>
							</>
						) : title.split(' ').slice(1).join(' ') === 'Yaris Cross' ? (
							<>
								Yaris <span>Cross</span>
							</>
						) : (title.includes('Model 3') || title.includes('Model Y')) ? (
							<>
								{title.split(' ').splice(-3, 2).join(' ')} <span>{title.split(' ').slice(-1)[0]}</span>
							</>
						) : (
							title.split(' ').slice(1).join(' ')
						)}
					</h2>
					<img
						className='rates-item__img'
						src={`./img/rates/${title}.png`}
						alt={title}
					/>
				</div>

				<div className='rates-item__price'>
					<span className='rates-item__subtitle'>{t('rate.header.price')}</span>
					<div className='rates-item__price_count'>
						<div
							className='rates-item__price_number'
							key={rates[ratesSwitch].costDayDrivingMovement}>
							{rates[ratesSwitch].costDayDrivingMovement}
						</div>
						<div className='rates-item__price_units'>
							<span className='rates-item__price_units-euro'>€</span>
							<span className='rates-item__price_units-min'>
								{t('main.min')}
							</span>
						</div>
					</div>
				</div>

				<div className='rates-item__daily'>
					<span className='rates-item__subtitle'>{t('rate.header.24h')}</span>
					{rates[ratesSwitch].currentPeriods['1d']} €
				</div>

				<div className='rates-item__arrow'>
					<svg
						width='14'
						height='8'
						viewBox='0 0 14 8'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M1 1L7 7L13 1'
							stroke='#E01254'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'></path>
					</svg>
				</div>
			</div>

			<div className='rates-item__body'>
				<img
					className='rates-body__img'
					src={`/img/rates/${title}.png`}
					alt={title}
				/>

				<div className='rates-item__wrapper'>
					<div className="rates-item__wrapper_2">

						{typeof rates[ratesSwitch]?.costDayMin !== 'undefined' && (
							<div className='rates-body__item'>
								<div className='rates-body__subtitle'>
									{t('rate.item.min_price')}
								</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].costDayMin}</span> €
								</div>
							</div>)}

						{typeof rates[ratesSwitch]?.propertyValue !== 'undefined' && (
							<div className='rates-body__item'>
								<div className='rates-body__subtitle rates-body__subtitle-info'>
									{t('rate.item.service_fee')}
									<div className='rates-body__info'>
										<div className='rates-body__tooltip big'>
											{t('rate.item.service_fee_info')}
										</div>
									</div>
								</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].propertyValue / 100}</span> €
								</div>
							</div>
						)}


						{typeof rates[ratesSwitch]?.costNightDrivingMovement !== 'undefined' && (
							<div className='rates-body__item'>
								<div className='rates-body__subtitle rates-body__subtitle-info'>
									{t('rate.item.driving_night')}
									<div className='rates-body__info'>
										<div className='rates-body__tooltip'>
											{t('rate.item.driving_night_info')}
										</div>
									</div>
								</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].costNightDrivingMovement}</span> €/
									{t('main.min')}
								</div>
							</div>)}

						{typeof rates[ratesSwitch]?.costDayParking !== 'undefined' && (
							<div className='rates-body__item'>
								<div className='rates-body__subtitle rates-body__subtitle-info'>
									{t('rate.item.waiting_day')}
									<div className='rates-body__info'>
										<div className='rates-body__tooltip'>
											{t('rate.item.waiting_day_info')}
										</div>
									</div>
								</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].costDayParking}</span> €/{t('main.min')}
								</div>
							</div>)}

						{typeof rates[ratesSwitch]?.costNightParking !== 'undefined' && (
							<div className='rates-body__item'>
								<div className='rates-body__subtitle rates-body__subtitle-info'>
									{t('rate.item.waiting_night')}
									<div className='rates-body__info'>
										<div className='rates-body__tooltip'>
											{t('rate.item.waiting_night_info')}
										</div>
									</div>
								</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].costNightParking}</span> €/
									{t('main.min')}
								</div>
							</div>
						)}

						{typeof rates[ratesSwitch]?.costDayAdditionalMileage !== 'undefined' && typeof rates[ratesSwitch]?.fixedFreeMileage !== 'undefined' && (
							<div className='rates-body__item'>
								<div className='rates-body__subtitle rates-body__subtitle-info'>
									{t('rate.item.additional_mileage')}
									{showFreeInfo && !isSecretDealRate && (
										<div className='rates-body__info'>
											<div className='rates-body__tooltip right'>
												{t('rate.item.additional_mileage_info')}
												{rates[ratesSwitch].fixedFreeMileage}
												{t('rate.item.additional_mileage_info_1')}
											</div>
										</div>
									)}
								</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].costDayAdditionalMileage}</span> €/{t('main.km')}
								</div>
							</div>
						)}
					</div>

					{!isSecretDealRate && (<>
						{typeof rates[ratesSwitch].currentPeriods?.['30m'] !== 'undefined' &&
							<div className='rates-body__item small' style={{ width: `${widthRatePeriod}%` }}>
								<div className='rates-body__subtitle'>{t('rate.item.30m')}</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].currentPeriods['30m']}</span> €
								</div>
							</div>
						}

						{typeof rates[ratesSwitch].currentPeriods?.['1h'] !== 'undefined' &&
							<div className='rates-body__item small' style={{ width: `${widthRatePeriod}%` }}>
								<div className='rates-body__subtitle'>{t('rate.item.1h')}</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].currentPeriods['1h']}</span> €
								</div>
							</div>
						}

						{typeof rates[ratesSwitch].currentPeriods?.['3h'] !== 'undefined' &&
							<div className='rates-body__item small' style={{ width: `${widthRatePeriod}%` }}>
								<div className='rates-body__subtitle'>{t('rate.item.3h')}</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].currentPeriods['3h']}</span> €
								</div>
							</div>
						}

						{typeof rates[ratesSwitch].currentPeriods?.['2d'] !== 'undefined' &&
							<div className='rates-body__item small' style={{ width: `${widthRatePeriod}%` }}>
								<div className='rates-body__subtitle'>{t('rate.item.2d')}</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].currentPeriods['2d']}</span> €
								</div>
							</div>
						}

						{typeof rates[ratesSwitch].currentPeriods?.['3d'] !== 'undefined' &&
							<div className='rates-body__item small' style={{ width: `${widthRatePeriod}%` }}>
								<div className='rates-body__subtitle'>{t('rate.item.3d')}</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].currentPeriods['3d']}</span> €
								</div>
							</div>
						}

						{typeof rates[ratesSwitch].currentPeriods?.['7d'] !== 'undefined' &&
							<div className='rates-body__item small' style={{ width: `${widthRatePeriod}%` }}>
								<div className='rates-body__subtitle'>{t('rate.item.7d')}</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].currentPeriods['7d']}</span> €
								</div>
							</div>
						}

						{typeof rates[ratesSwitch].currentPeriods?.['14d'] !== 'undefined' &&
							<div className='rates-body__item small' style={{ width: `${widthRatePeriod}%` }}>
								<div className='rates-body__subtitle'>{t('rate.item.14d')}</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].currentPeriods['14d']}</span> €
								</div>
							</div>
						}

						{typeof rates[ratesSwitch].currentPeriods?.['30d'] !== 'undefined' &&
							<div className='rates-body__item small' style={{ width: `${widthRatePeriod}%` }}>
								<div className='rates-body__subtitle'>{t('rate.item.30d')}</div>
								<div className='rates-body__price'>
									<span>{rates[ratesSwitch].currentPeriods['30d']}</span> €
								</div>
							</div>
						}
					</>)}
				</div>
			</div>
		</div>
	)
}
export default RatesItem
