import React, { useLayoutEffect } from 'react'

import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'


import Buttons from '../../../../components/Buttons/Buttons'
import Spinner from '../../../../components/Spinner/Spinner'

import './promo.sass'
import './animate.css'

import bg from './img/bg.svg'
import cars from './img/cars.png'

import { useSelector } from 'react-redux'
import { useState } from 'react'
import { isSecretDealRate, showFreeInfo } from '../../../../App/App'


const Promo = () => {
  const { t } = useTranslation()

  const [randomCar] = useState(`./img/promo/${Math.floor(Math.random() * 7) + 1}.png`)
  const [showCars, setShowCars] = useState(false)

  useLayoutEffect(() => {
    setShowCars(true)
  }, [])

  const minPrice = useSelector((state) => state.minPrice)
  return (
    <section className="promo" style={{ backgroundImage: `url(${bg})` }}>
      <div className="container">
        <div className="promo-block">
          <h1 className="promo-block__title">
            <Trans>{t("main.title")}</Trans>
          </h1>

          <div className="promo-block__total">
            <div className="total-title">{t("main.drive_from")}</div>
            <div className="total-price">
              <span className="total-price__number">{minPrice}</span>
              <div className="total-price__units">
                <span className="total-price__units-euro">€</span>
                <span className="total-price__units-min">{t("main.min")}</span>
              </div>
            </div>
            <div className="total-subtitle">
              {t("main.price_debit.main")}
              {showFreeInfo && !isSecretDealRate && t("main.price_debit.notSecretDealRate")}
              <Link to="/rates">{t("nav.more")}</Link>
            </div>
            <div className="total-buttons">
              <Buttons onDark />
            </div>
          </div>
        </div>
      </div>

      {!showCars && (<Spinner onSmall />)}

      <CSSTransition
        in={showCars}
        timeout={600}
        classNames="promo-animation"
        mountOnEnter
      >
        <div className="promo-cars">
          <div className="promo-car__before" style={{ backgroundImage: `url(${cars})` }}></div>
          <div className="promo-car">
            <img src={randomCar} alt="Car" />
          </div>
          <div className="promo-car__after" style={{ backgroundImage: `url(${cars})` }}></div>
        </div>
      </CSSTransition>

    </section>
  )
}

export default Promo