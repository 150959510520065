import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import WithService from '../../services/hoc'
import ReactPlayer from 'react-player'
// import ModalVideo from 'react-modal-video'

import Spinner from '../../components/Spinner/Spinner'
import Error from '../../components/Error/Error'

import './mobileArticle.sass'

function b64DecodeUnicode(str) {
	return decodeURIComponent(
		atob(str)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join('')
	)
}

const MobileArticle = props => {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const history = useHistory()

	const [newItem, setNewItem] = useState(false)
	const [newVideo, setNewVideo] = useState(false)
	const [newImage, setNewImage] = useState(false)

	// const [newIdVideo, setNewIdVideo] = useState(false)
	// const [openVideo, setOpenVideo] = useState(false)
	const [darkTheme, setDarkTheme] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		if (queryParams.get('theme') === 'dark') {
			setDarkTheme(true)

			queryParams.delete('theme')
			history.replace({ search: queryParams.toString() })
		}

		if (location?.pathname?.[location?.pathname?.length - 1] === '/')
			history.push({ pathname: location?.pathname?.slice(0, -1) })

		const locationArray = location?.pathname?.substring(16).split('/')
		const lng = locationArray?.[0],
			idNew = locationArray?.[1].split('-')[0]

		const { Service } = props
		Service.getMobileArticle(lng, idNew)
			.then(res => {
				const newsData = {}
				newsData.title = res?.result?.[0]?.titles?.[0]?.val
				newsData.description = b64DecodeUnicode(
					res?.result?.[0]?.descriptions?.[0]?.val
				)
				newsData.alias = res?.result?.[0]?.alias
				newsData.id = res?.result?.[0]?.id
				newsData.publishedAt = res?.result?.[0]?.publishedAt * 1000
				newsData.medias = res?.result?.[0]?.medias

				document.title = `${newsData?.title} - CARGURU`
				history.replace({ pathname: `${idNew}-${newsData?.alias}` })

				const date = new Date(newsData?.publishedAt)
				newsData.date = date.toLocaleDateString(
					lng === 'ru' ? 'ru-RU' : 'en-GB',
					{
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					}
				)

				if (newsData.description.indexOf('[media ') >= 0) {
					let start = newsData?.description?.indexOf('[media'),
						end = newsData?.description?.indexOf('[/media]') + 8 - start

					let newVideo = newsData?.description?.substr(start, end)
					newVideo = newVideo?.slice(newVideo?.indexOf(']') + 1).split('[/')[0]

					// const idVideo = (newVideo.indexOf('watch?v=') >= 0)
					//   ? newVideo.substring(+newVideo.indexOf('watch?v=') + 8).split('/')[0]
					//   : newVideo.substring(+newVideo.indexOf('youtu.be/') + 9).split('/')[0]
					// setNewIdVideo(idVideo)

					setNewVideo(newVideo)

					newsData.description =
						newsData?.description?.split('[media')[0] +
						newsData?.description?.split('[/media]')[1]
					setNewItem(newsData)
				} else if (
					newsData?.medias &&
					newsData?.medias?.[0] &&
					newsData?.medias?.[0]?.salt
				) {
					const img = new Image()
					img.src = `https://media.carguru.online/web/news/${newsData.medias[0].salt}`

					setNewImage(true)

					img.onload = () => setNewItem(newsData)
				}
			})
			.catch(error => {
				console.error(error)
				setError(true)
			})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!newItem && !error) return <Spinner />
	if (error) return <Error mobile dark={darkTheme ? true : false} />

	return (
		<div
			className='mobileArticle'
			style={{
				background: darkTheme ? '#292F35' : '#FFFFFF',
				color: darkTheme ? '#FFFFFF' : '#2F3059',
			}}>
			{newVideo ? (
				<>
					<div
						className='mobileArticle-wrapper'
					// onClick={() => setOpenVideo(true)}
					>
						<ReactPlayer
							className='mobileArticle-video'
							url={newVideo}
							controls={true}
						/>
					</div>

					{/* <ModalVideo 
          channel='youtube' 
          videoId={newIdVideo} 
          autoplay
          youtube={{mute:1,autoplay:1}}
          isOpen={openVideo} 
          onClose={() => setOpenVideo(false)} 
        /> */}
				</>
			) : (
				<>
					{newImage && (
						<div className='mobileArticle-wrapper'>
							<img
								className='mobileArticle-img'
								src={`https://media.carguru.online/web/news/${newItem?.medias?.[0]?.salt}`}
								alt={newItem?.title}
							/>
						</div>
					)}
				</>
			)}

			<div className='container'>
				<div
					className='mobileArticle-date'
					style={{ marginTop: !newVideo && !newImage ? '68px' : '0px' }}>
					<span>{newItem?.date}</span>
				</div>

				<h2 className='news-item__title mobileArticle-title'>
					{newItem?.title}
				</h2>

				<div
					className='news-item__text mobileArticle-text'
					dangerouslySetInnerHTML={{ __html: newItem?.description }}></div>
			</div>
		</div>
	)
}

export default WithService()(MobileArticle)
