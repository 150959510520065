import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Link, useLocation } from "react-router-dom"
import { Trans, useTranslation } from 'react-i18next'

import Nav from '../Nav/Nav'

import './header.sass'
import './animate.css'

import logo from './logo.svg'


const Header = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const [navOpened, setNavOpened] = useState(false)

  const toggleNav = (show) => {
    const scrollWidth = () => {
      let div = document.createElement('div')
      div.style.width = '50px'
      div.style.height = '50px'
      div.style.overflowY = 'scroll'
      div.style.visibility = 'hidden'

      document.body.appendChild(div)
      let scrollWidth = div.offsetWidth - div.clientWidth
      div.remove()

      return scrollWidth
    }

    if (show) {
      document.body.style.overflow = 'hidden'
      document.body.style.marginRight = `${scrollWidth()}px`
      setNavOpened(true)
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.marginRight = '0px'
      setNavOpened(false)
    }
  }
  const showNav = () => toggleNav(true)
  const hideNav = () => toggleNav(false)


  return (
    <>
      <CSSTransition
        in={navOpened}
        timeout={300}
        classNames="nav-animation"
        unmountOnExit
      >
        <Nav onClose={hideNav} />
      </CSSTransition>

      <header className={(location.pathname !== "/business" ? 'header' : 'header header_dark')}>
        <div className="container">
          <div className="header-buttons">

            <div className="header-wrapper">
              <div className="header-button header-button__logo">
                <Link to='/'>
                  <img src={logo} alt="Carguru" width={60} header={41} />
                </Link>
              </div>

              {(location.pathname !== '/' && location.pathname !== "/business") && (
                <div className="header-title">

                  {(
                    location.pathname.slice(1).split('/')[0] === 'article')
                    ?
                    <h2 className='header-title__h1'>{t('nav.news')}</h2>
                    :
                    <>{(
                      location.pathname.slice(1).split('/')[0] === 'login')
                      ?
                      <h1>{t('nav.authorization')}</h1>
                      :
                      <>{(
                        location.pathname.slice(1).split('/')[0] === 'event')
                        ?
                        <h1 className="header-title__event">{t('event.header.current')}</h1>
                        :
                        <>{(
                          location.pathname.slice(1).split('/')[1] === 'referral-program')
                          ?
                          <h1><Trans>{t(`loyalty.referral-program.header`)}</Trans></h1>
                          :
                          <>{(
                            location.pathname.slice(1).split('/')[1] === 'bonuses-and-levels')
                            ?
                            <h1>{t(`loyalty.bonuses-and-levels.title`)}</h1>
                            :
                            <h1>{t(`nav.${location.pathname.slice(1).split('/')[0]}`)}</h1>
                          }</>
                        }</>
                      }</>
                    }</>
                  }

                  {(
                    location.pathname.slice(1).split('/')[0] === 'faq' ||
                    location.pathname.slice(1).split('/')[0] === 'legal' ||
                    location.pathname.slice(1).split('/')[0] === 'guide' ||
                    location.pathname.slice(1).split('/')[0] === 'loyalty')
                    ?
                    <>{(
                      location.pathname.slice(1).split('/')[1] === 'referral-program') ? '' :
                      <span>{t('header.subtitle_information')}</span>
                    }</>
                    :
                    <>{(
                      location.pathname.slice(1).split('/')[0] === 'login')
                      ?
                      <span>{t('header.subtitle_authorization')}</span>
                      :
                      <>{(
                        location.pathname.slice(1).split('/')[0] !== 'event')
                        ?
                        <span>{t('header.subtitle')}</span>
                        :
                        null
                      }</>
                    }</>
                  }

                </div>
              )}
            </div>

            <div
              onClick={showNav}
              className="header-button header-button__menu"
            >
              <span></span>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header