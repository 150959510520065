import { useEffect, useState } from "react"
import { Redirect, useLocation } from "react-router"
import WithService from '../../services/hoc'
import ReactPlayer from 'react-player'
import { Helmet } from "react-helmet"
import { useSelector } from 'react-redux'

import Spinner from '../../components/Spinner/Spinner'

function b64DecodeUnicode(str) {
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

const NewsPage = (props) => {
  const location = useLocation()
  const language = useSelector((state) => state.language)

  const [redirectTo, setRedirectTo] = useState(false)

  const [newsItem, setNewsItem] = useState(false)
  const [newsVideo, setNewsVideo] = useState(false)

  useEffect(() => {
    const { Service } = props
    Service.getNews({ id: location.pathname.replace('/article/', ''), lng: language })
      .then(res => {

        const newsData = {}
        newsData.title = res?.result?.[0]?.titles?.[0]?.val
        newsData.description = b64DecodeUnicode(res?.result?.[0]?.descriptions?.[0]?.val)
        newsData.alias = res?.result?.[0]?.alias
        newsData.id = res?.result?.[0]?.id
        newsData.publishedAt = res?.result?.[0]?.publishedAt
        newsData.medias = res?.result?.[0]?.medias

        document.title = `${newsData?.title || 'News'} - CARGURU`

        const date = new Date(newsData?.publishedAt * 1000)
        newsData.date = date.toLocaleDateString((language === 'ru') ? 'ru-RU' : 'en-GB', { day: 'numeric', month: 'short', year: 'numeric' })

        if (newsData?.description?.indexOf('[media ') >= 0) {
          let start = newsData?.description?.indexOf('[media'),
            end = newsData?.description?.indexOf('[/media]') + 8 - start

          let newVideo = newsData?.description?.substr(start, end)
          newVideo = newVideo?.slice(newVideo.indexOf(']') + 1).split('[/')[0]
          setNewsVideo(newVideo)

          newsData.description = newsData?.description?.split('[media')[0] + newsData?.description?.split('[/media]')[1]
          setNewsItem(newsData)
        } else if (newsData?.medias && newsData?.medias?.[0] && newsData?.medias?.[0]?.salt) {
          const img = new Image()
          img.src = `https://media.carguru.online/web/news/${newsData.medias[0].salt}`

          img.onload = () => setNewsItem(newsData)
        }

      })
      .catch((error) => {
        console.error(error)
        setRedirectTo(true)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])


  if (location.pathname === '/article' || location.pathname === '/article/' || redirectTo) return <Redirect to="/news" />

  if (!newsItem) return <Spinner onSmall />

  return (<>
    <Helmet>
      <meta property="og:URL" content={`https://carguru.lv${location.pathname}`} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`${newsItem.title} - CARGURU`} />
      <meta property="og:description" content="" />
      {(newsItem?.medias && newsItem?.medias?.[0] && newsItem?.medias?.[0]?.salt) &&
        <meta property="og:image" content={`https://media.carguru.online/web/news/${newsItem.medias[0].salt}`} />
      }
    </Helmet>

    <div className="news-item">
      <div className="news-item__date">
        <span>{newsItem?.date}</span>
      </div>

      <h1 className="news-item__title">{newsItem?.title}</h1>

      {newsVideo ? (
        <ReactPlayer
          className="news-item__img"
          url={newsVideo}
          controls={true}
        />
      ) : (<>
        {(newsItem?.medias && newsItem?.medias?.[0] && newsItem?.medias?.[0]?.salt) && (
          <img
            className="news-item__img"
            src={`https://media.carguru.online/web/news/${newsItem.medias[0].salt}`}
            alt={newsItem.title}
          />
        )}
      </>)}

      <div className="news-item__text" dangerouslySetInnerHTML={{ __html: newsItem?.description }}></div>
    </div>
  </>)
}

export default WithService()(NewsPage)